// extracted by mini-css-extract-plugin
export var eyebrow = "style-module--eyebrow--Qbzhv";
export var ctBlack = "style-module--ctBlack--3AEY1";
export var ctSmall = "style-module--ctSmall--1_9ud";
export var ctMedium = "style-module--ctMedium--y5zNd";
export var ctLarge = "style-module--ctLarge--2l0ua";
export var ctXLarge = "style-module--ctXLarge--1nVRC";
export var ctIntro = "style-module--ctIntro--nhq2a";
export var ctBody = "style-module--ctBody--3xTfK";
export var subscribeButton = "style-module--subscribeButton--_3Jdg";
export var link = "style-module--link--2rlPP";
export var cta = "style-module--cta--1azsf";
export var description = "style-module--description--3DLgd";
export var secondLogoPresentation = "style-module--secondLogoPresentation--38qUa";
export var ctBodyMedium = "style-module--ctBodyMedium--d267e";
export var ctBodyBold = "style-module--ctBodyBold--GJlkd";
export var ctLink = "style-module--ctLink--1bp_X";
export var frutiLarge = "style-module--frutiLarge--2ZRtf";
export var ctUi = "style-module--ctUi--2Es5u";
export var ctMeta = "style-module--ctMeta--1khvr";
export var ctMicro = "style-module--ctMicro--1hKlI";
export var ctMediumItalic = "style-module--ctMediumItalic--16urS";
export var footer = "style-module--footer--15DU7";
export var blueBorder = "style-module--blueBorder--38CrE";
export var noMargin = "style-module--noMargin--2ffsE";
export var top = "style-module--top--h1Wfo";
export var bottom = "style-module--bottom--8IZ2f";
export var logo = "style-module--logo--1t_El";
export var secondLogo = "style-module--secondLogo--11oPe";
export var ctas = "style-module--ctas--2g7rx";
export var nav = "style-module--nav--215Jg";
export var right = "style-module--right--30LDD";